@tailwind base;
@tailwind components;
@tailwind utilities;

.str-chat__list {
    margin-left: 1rem;
    margin-right: 1rem;
}

.min-w-inherit {
    min-width: inherit;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.q-button {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
